import React, { useState } from 'react';
import styled from 'styled-components';
import { accountsConfig } from '../../../axiosConfig';
import topSvg from '../../../assets/images/indianearth/cformhead.png';
import ClipLoader from 'react-spinners/ClipLoader';
import Swal from "sweetalert2";
import bgImage from '../../../assets/images/indianearth/formcover.jpeg';

const ContactFormSection = () => {
    const [formData, setformData] = useState({ name: '', phone: '', email: '', message: '' });
    const [loading, setloading] = useState(false);
    let [color, setColor] = useState("#ffffff");

    const handleChange = (e) => {
        const { name, value } = e.target
        setformData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const submitEnquiry = (e) => {
        e.preventDefault()
        setloading(true)
        const form = new FormData();
        Object.entries(formData).forEach((item) => {
            form.append(item[0], item[1]);
        })

        accountsConfig.post('projects/quick-enquiry/', form).then((response) => {
            const { data, StatusCode } = response.data.app_data;
            if (StatusCode === 6000) {
                setloading(false)
                e.target.reset()
                showSuccessAlert();
            }
        })

    }
    return (
        <Container>
            <LeftSection />
            <RightSection>
                <TopStrip src={topSvg} alt="Top Strip" />
                <ContentWrapper>
                    <Box className="enquiry-form">

                        <Form onSubmit={(e) => submitEnquiry(e)}>
                            <FormItem className="fw">
                                <Name>
                                    Your Name*
                                </Name>
                                <TextInput
                                    name="name"
                                    type="text"
                                    required={true}
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Email
                                </Name>
                                <TextInput
                                    name="email"
                                    type="email"
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormItem>
                            <FormItem>
                                <Name>
                                    Number*
                                </Name>
                                <TextInput
                                    name="phone"
                                    type="number"
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </FormItem>
                            <FormItem className="fw">
                                <Name>
                                    Message
                                </Name>
                                <TextAreaInput
                                    name="message"
                                    type="text"
                                    required
                                    rows={2}
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormItem>

                            <SubmitBtn>
                                {loading ? <ClipLoader
                                    color={color}
                                    loading={loading}
                                    size={20}
                                    aria-label="Loading Spmargin: 0 auto;inner"
                                    data-testid="loader"
                                /> : "request a call back"}
                            </SubmitBtn>

                        </Form>
                    </Box>
                </ContentWrapper>
                <BottomStrip src={topSvg} alt="Bottom Strip" />
            </RightSection>
        </Container>
    );
};

export default ContactFormSection;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 600px;

    @media (max-width: 767px) {
        flex-direction: column; /* Image on top, Form at bottom */
        min-height: auto;
    }
`;

const LeftSection = styled.div`
    width: 50%;
    background: url(${bgImage}) no-repeat center center/cover;

    @media (max-width: 767px) {
        width: 100%;
        height: 300px; /* Set a fixed height for mobile */
    }
`;

const RightSection = styled.div`
     width: 50%;
    margin: 40px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 40px 20px;

 @media (max-width: 767px) {
    width: 90%; /* Keeps width proportional */
    max-width: 100%; /* Ensures no unwanted shrinking */
    margin: auto; /* Centers it properly */
    padding: 30px 15px; /* Internal padding for spacing */
}
`;
const TopStrip = styled.img`
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%; /* Ensures it doesn’t exceed container width */
    height: auto; /* Maintains aspect ratio */

    @media (max-width: 768px) {
        position: relative; /* Prevents breaking */
        width: 100%;
        height: auto;
    }
`;

const BottomStrip = styled.img`
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 100%; /* Ensures it doesn’t exceed container width */
    height: auto; /* Maintains aspect ratio */

    @media (max-width: 768px) {
        position: relative; /* Prevents breaking */
        width: 100%;
        height: auto;
    }
`;

const ContentWrapper = styled.div`
    text-align: center;
    z-index: 1;
    margin:10px;
`;

const Heading = styled.h2`
    font-size: 32px;
    margin-bottom: 10px;
`;

const Subtitle = styled.p`
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
`;

const FormContainer = styled.form`
    background-color:rgb(228, 33, 33);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 0;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    margin: 20px
`;

const FormRow = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    & > input, & > textarea {
        flex: 1;
    }
`;

const Input = styled.input`
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
`;

const Textarea = styled.textarea`
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
`;

const SubmitButton = styled.button`
    background-color: #014D68;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #012f47;
    }
`;

const Box = styled.div`
  flex: 1;
  display: flex;
  
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: flex-start;
  width: 100%; // Ensure full width

  &.enquiry-form {
    background-color: #fff;
    justify-content: flex-start;
  }

  @media (max-width: 1200px) {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 580px) {
    &.address {
      width: 100%; // Full width
      margin: 0;
    }

    &.enquiry-form {
      background-color: #F5F5F5;
      width: 100%; // Full width
    }
  }
`;

const BoxTitle = styled.p`
    color: #606060;
    font-family: 'gellix-regular';
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.51px;color: #606060;
    font-family: Gellix;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.51px;
    margin: 0;
    padding: 0;
`;
const Form = styled.form`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: 90%; // Full width of parent
        gap: 25px; // Not as dramatic reduction
    }
`;


const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    width:45%;
    gap: 15px;

    &.fw{
        width: 100%;
    }

    @media(max-width:580px){
      width: 100%;
    }
`;
const Name = styled.div`
        color: #706E6C;
        text-align: justify;
        font-family: 'gellix-regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%; /* 28px */
`;

const TextInput = styled.input`
      outline:none;
      border: none;
      border-bottom: 1px solid #606060;

      @media(max-width:580px){
        background-color: transparent;
      }
`;

const TextAreaInput = styled.textarea`
      outline:none;
      border: none;
      border-bottom: 1px solid #606060;
      resize: vertical;

      @media(max-width:580px){
        background-color: transparent;
      }
`;

const SubmitBtn = styled.button`
    padding: 16px 27px;
    background: #014D68;
    outline: none;
    border: none;
    color: #FFF;
    font-family: 'gellix-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 20px */
    cursor: pointer;
`;

const showSuccessAlert = () => {
    Swal.fire({
        position: "center",
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1500,
        iconColor: "#0e2d5e",
    });
};
