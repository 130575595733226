import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import bgImage from '../../../assets/images/indianearth/fusionbg.jpg'
import thumbnail from '../../../assets/images/indianearth/fusionimg.png'
import { ReactComponent as Logo } from '../../../assets/images/indianearth/fusionlogo.svg'
import ReactPlayer from "react-player";
import Modal from "react-modal";
import { ReactComponent as PlayIcon } from '../../../assets/images/indianearth/Play.svg'

const videoSrc = "/videos/video.mp4";


const FusionSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FusionContainer>
      <ContentWrapper>
        <TextContent>
          <TitleRow>
            <StyledLogo />
            <TitleWrapper>
              <VerticalBar />
              <Title>a fusion of craft, culture, <RedText>and comfort</RedText></Title>
            </TitleWrapper>
          </TitleRow>

          <Description>indian earth blends heritage and luxury with fine craftsmanship, premium materials, and elegant finishes. every space tells a story of art, tradition, and sophistication. it reflects india’s rich culture, offering a home where tradition meets modern comfort in a space designed for timeless living.</Description>
          <FeatureList>
            <FeatureItem><BoldText>architectural detailing </BoldText>: inspired by heritage and artistry.</FeatureItem>
            <FeatureItem><BoldText>materials & finishes </BoldText>: handpicked textures and timeless aesthetics.</FeatureItem>
            <FeatureItem><BoldText>exclusive craftsmanship</BoldText>: unique facades, intricate arches, and traditional motifs.</FeatureItem>
            <FeatureItem><BoldText>luxury with culture</BoldText>: a seamless blend of history and contemporary living.</FeatureItem>
          </FeatureList>
        </TextContent>


        <ImageWrapper onClick={() => setIsOpen(true)}>
          <StyledImage src={thumbnail} alt="Play Video" />
          <PlayButton><PlayIcon /></PlayButton>
        </ImageWrapper>

        {/* 🔹 Popup Video Player */}
        <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={modalStyles}>
          <CloseButton onClick={() => setIsOpen(false)}>✖</CloseButton>
          <ReactPlayer url={videoSrc} playing controls width="100%" height="100%" />
        </Modal>
      </ContentWrapper>
    </FusionContainer>
  )
}

export default FusionSection


const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between VerticalBar and Title */

  @media (max-width: 767px) {
    width: 100%; /* Ensure it doesn't wrap */
  }
`;

const FusionContainer = styled.section`
  position: relative;
  width: 100%;
  background: rgba(0, 0, 0, 0.9) url(${bgImage}) no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;


const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Reduce width for better centering */
  max-width: 1200px; /* Ensure it doesn't stretch too wide */
  margin: auto; /* Center it horizontally */

  @media (max-width: 767px) {
    flex-direction: column-reverse; /* Stack on smaller screens */
    align-items: center;
  }
`;


const TextContent = styled.div`
width: 50%;
  flex: 1;
  text-align: left;
  margin: 40px;
  
  @media (max-width: 767px) {
    width: 90%;

  }
`;

const Title = styled.h2`
  font-size: 38px;
  font-weight: 400;
  color: #18294A;
  line-height: 38px;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 24px;
  }

  /* ✅ Corrected the tablet media query */
  @media (min-width: 767px) and (max-width: 1024px) {
    font-size: 32px;
    line-height: 32px;
  }
`;



const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the left */
    gap: 5px; /* Reduce gap */
  }
`;

const StyledLogo = styled(Logo)`
  height: 86px; /* Default size */
  width: auto;

  @media (max-width: 767px) {
    height: 47px; /* Smaller size for mobile screens */
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    height: 52px; /* Medium size for tablets */
  }
`;


const VerticalBar = styled.div`
  width: 4px;
  height: 70px;
  background-color: #FF0000;
 
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  line-height: 156%;
  color: #6D7B96;
  
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  max-width: 100%; 
  @media (min-width: 641px) and (max-width: 1023px) {
    display: none;  // Hide only for tablets
  }
`;

const FeatureItem = styled.li`
  color: #6D7B96;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  padding-left: 20px; /* Add padding for the bullet point */
  position: relative; /* For positioning the bullet */
  
  @media (max-width: 767px) {
    font-size: 12px;
  }

  /* Position the bullet point absolutely so the text doesn't wrap below it */
  &::before {
    content: "●";
    color: red;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 2px; /* Adjust vertical alignment */
  }
`;
const ImageWrapper = styled.div`
  width: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  position: relative; /* For PlayButton positioning */
  
  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
  
  /* Add this for tablet-sized screens */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 45%; /* Slightly smaller width on tablets */
    margin: 20px; /* Reduced margin */
  }
`;

const StyledImage = styled.img`
  width: 100%; /* Use 100% of the parent container width */
  max-width: 580px; /* But don't exceed this maximum width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 10%;

  /* Increase height for different screen sizes */
  @media (min-width: 1025px) {
    height: 400px; /* Increase height for larger screens */
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 320px; /* Increase height for tablets */
  }

  @media (max-width: 767px) {
    border-radius: 0;
    height: 260px; /* Increase height for mobile screens */
  }
`;

const RedText = styled.span`
  color: red;
`;

const BoldText = styled.span`
  color: #18294A;
  font-weight: 700;
  display: inline;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 60px;
    height: 60px;
    fill: white;
     @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`;

/* 🔹 Modal Styles */
const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1000
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "800px",
    background: "black",
    border: "none",
    padding: "0",
    overflow: "visible"
  }
};

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  z-index: 1000; /* Add high z-index */
  width: 40px; /* Give it some width */
  height: 40px; /* Give it some height */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
`;