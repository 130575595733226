import React from 'react'
import styled from 'styled-components'
import one from '../../../assets/images/indianearth/lobby_out.webp'
import two from '../../../assets/images/indianearth/lift_lobby.webp'
import three from '../../../assets/images/indianearth/living.png'
import four from '../../../assets/images/indianearth/bedroom.png'


// Define styled components first to avoid reference issues
const TextContainer = styled.div`
  padding: 0 15px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: clamp(32px, 5vw, 56px); /* Responsive size */
  font-family: "Viaoda Libre", serif;
  margin-bottom: 5px;
  font-variant-ligatures: none;

  .red-dot {
    position: relative;
    display: inline-block;
  }

  .red-dot::after {
    content: "•";  /* Adds a red dot above */
    color: red;
    font-size: 0.51em;  /* Reduce dot size */
    position: absolute;
    top: -0.48em;  /* Lower the dot slightly */
    left: 55%;
    transform: translateX(-50%);
  } 
`;

const Subtitle = styled.p`
  margin: 0;
  font-size: clamp(14px, 1.8vw, 16px); /* Responsive size */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
`;

const VerticalBar = styled.div`
  width: 4px;
  height: clamp(40px, 6vw, 60px); /* Responsive height */
  background-color: red;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 images per row */
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 image per row on small screens */
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;


  @media (max-width: 1024px) {
    height: 40vh;
  }
  @media (max-width: 426px) {
    height: 30vh;
  }
`;

const GridImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextOverlay = styled.div`
  position: absolute;
  bottom: 20px;
  ${props => props.position === 'right' ? 'right: 20px; text-align: right;' : 'left: 20px; text-align: left;'}
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    left: 20px;
    right: auto;
    text-align: left;
    flex-direction: row;
    
    /* Rearrange elements for mobile */
    display: flex;
    flex-direction: row;
    
    /* Force the bar to be first */
    & .bar {
      order: -1;
    }
    
    /* Force the text container to be second */
    & > div {
      order: 1;
    }
  }
`;

const CardSection = () => {
  return (


    <>
      <ImageGrid>
        <ImageContainer>
          <GridImage src={one} alt="Image 1" />
          <TextOverlay position="right">
            <TextContainer>
              <Title>main lobby</Title>
              <Subtitle>Where heritage meets modernity</Subtitle>
            </TextContainer>
            <VerticalBar className="bar" />
          </TextOverlay>
        </ImageContainer>

        <ImageContainer>
          <GridImage src={two} alt="Image 2" />
          <TextOverlay position="left">
            <VerticalBar className="bar" />
            <TextContainer>
              <Title>lobby lift</Title>
              <Subtitle>Elegance and accessibility,elevated</Subtitle>
            </TextContainer>
          </TextOverlay>
        </ImageContainer>

        <ImageContainer>
          <GridImage src={three} alt="Image 3" />
          <TextOverlay position="right">
            <TextContainer>
              <Title>living room</Title>
              <Subtitle>A space to craft & cherish your legacy</Subtitle>
            </TextContainer>
            <VerticalBar className="bar" />
          </TextOverlay>
        </ImageContainer>

        <ImageContainer>
          <GridImage src={four} alt="Image 4" />
          <TextOverlay position="left">
            <VerticalBar className="bar" />
            <TextContainer>
              <Title>bedroom</Title>
              <Subtitle>Where your dreams find comfort & rest</Subtitle>
            </TextContainer>
          </TextOverlay>
        </ImageContainer>
      </ImageGrid>
    </>
  )
}

export default CardSection