import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getFirstAndBalanceWords } from "../../general/common/helper";
import soldOut from "../../../assets/images/apartments/icons/sold-out.svg";

export default function ApartmentList({ aprtmentList }) {
  return (
    <MainContainer>
      <Content>
        <Wrapper className="wrapper">
          <Cards>
            {(aprtmentList || []) 
            .sort((a, b) => (a.name === "INDIAN EARTH" ? -1 : b.name === "INDIAN EARTH" ? 1 : 0))
            .map((item, index) => {
              // Use the function to get the first word and the rest of the words
              const result = getFirstAndBalanceWords(item.name);

              return (
                <Card
                  key={index}
                  to={item.name === "INDIAN EARTH" ? "/indian-earth" : `/apartments-for-sale-in-kochi/${item.slug}`}
                >
                  <ImageContainer isSold={item?.soldout ? true : false}>
                    <img src={item.thumbnail} alt={item.name} />
                  </ImageContainer>
                  {item?.project_status && (
                    <StatusView>
                      <Status>{item?.project_status}</Status>
                      <DateInfo>
                        <span>{item?.completed_year}</span>
                      </DateInfo>
                    </StatusView>
                  )}

                  <Title>
                    <span>{result.firstWord}</span>
                    {result.balanceWords}
                  </Title>
                  <Address>{item.location}</Address>
                </Card>
              );
            })}
          </Cards>
        </Wrapper>
      </Content>
    </MainContainer>
  );
}

const MainContainer = styled.div`
padding: 40px 0 80px 0;
  background-color: #f8f8f8;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const TopBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 30px;
  @media all and (max-width: 480px) {
    gap: 20px;
  }
`;
const Item = styled.h3`
  color: #777777;
  cursor: pointer;
  padding-bottom: 10px;
  font-family: "gellix-medium";
  @media all and (max-width: 480px) {
    font-size: 14px;
  }

  &.active {
    color: #eb6502;
    border-bottom: 1px solid #eb6502;
  }
`;
const Cards = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  flex-wrap: wrap;
`;
const Card = styled(Link)`
  width: 48%;
  cursor: pointer;
  position: relative;

  @media all and (max-width: 1680px) {
    width: 46%;
  }
  @media all and (max-width: 768px) {
    width: 80%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  position: relative;

  &::after {
    ${(props) => (props.isSold ? "display: flex;" : "display:none;")}
    content: '';
    background-image: url(${soldOut});
    position: absolute;
    background-size: contain;
    height: 65px;
    width: 65px;
    top: 15px;
    right: 15px;
  }
`;

const Title = styled.h2`
  color: #606060;
  margin: 20px 0;
  letter-spacing: 2px;
  text-align: center;
  font-family: "gellix-bold";
  color: #606060;
  span {
    color: #eb6502;
    margin-right: 5px;
  }
  @media all and (max-width: 480px) {
    font-size: 18px;
  }
`;
const Address = styled.div`
  color: #2b2a29;
  text-align: center;
  font-family: "gellix-medium";
  letter-spacing: 1px;
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;

const StatusView = styled.div`
  position: absolute;
  top: 18px;
  left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 200px;
  height: 40px;

  @media screen and (max-width: 1024px) {
    width: 180px;
    top: 15px;
    left: 15px;
  }

  @media screen and (max-width: 580px) {
    top: 10px;
    left: 13px;
    gap: 5px;
  }
`;

const Status = styled.div`
  background-color: #eb6502;
  border-radius: 5px;
  font-family: "gellix-medium";
  padding: 8px 16px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`;

const DateInfo = styled.div`
  color: #fff;
  position: relative;
  width: 100%;
  font-family: "gellix-medium";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  span {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    width: 65%;
    text-align: center;
    padding: 3px 6px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 35px;
    top: -25px;
    right: -6px;
    border: 1.5px solid #fff;
    border-left: none;
  }

  &::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 35px;
    top: -25px;
    left: -6px;
    border: 1.5px solid #fff;
    border-right: none;
  }

  @media screen and (max-width: 580px) {
    &::before {
      width: 25px;
    }

    &::after {
      width: 25px;
    }
  }
`;
