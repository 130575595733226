import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styled from "styled-components";

const SwiperComponent = ({ images }) => {
  return (
    <SwiperContainer>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        breakpoints={{
          768: { slidesPerView: 2,
            spaceBetween: 0
           },
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <ImageContainer>
              <Image src={image} alt={`slide-${index}`} />
            </ImageContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  );
};

export default SwiperComponent;

const SwiperContainer = styled.div`
  width: 100%;
  margin: auto;
  
  /* Change navigation arrows to white */
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
  }
  
  /* Change pagination dots to white */
  .swiper-pagination-bullet {
    background: white;
    opacity: 0.7;
  }
  
  .swiper-pagination-bullet-active {
    background: white;
    opacity: 1;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 310px; /* Set a fixed height */
  overflow: hidden;
   @media (min-width: 768px) {
    height: 327px; /* Tablets */
  }
     @media (min-width: 1024px) {
    height: 610px; /* Tablets */
  }
`;


const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
`;
