import React, { useState } from "react";
import styled from "styled-components";
import brochure from "../../../assets/brochure.pdf";
import icon1 from '../../../assets/images/indianearth/house-chimney.png';
import icon2 from '../../../assets/images/indianearth/phone-call.png';
import icon3 from '../../../assets/images/indianearth/envelope.png';
import ContactFormSection from "./ContactModal";

const LegacySection = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = brochure;
    link.download = "brochure.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Container>
        {/* Left Section */}
        <Section>
          <ContentWrapper>
            <Title>the legacy of fine living</Title>
            <Subtitle>the essence of india, captured in modern luxury.</Subtitle>
            <Description>
              indian Earth by Classic Homes is not just a home; it's an experience that seamlessly combines India's architectural elegance with contemporary comfort. With its artisan apartment designs and prime location, this project is your gateway to modern living with a cultural touch.
            </Description>
            <ButtonWrapper>
              <ActionButton onClick={() => setShowModal(true)}>download brochure</ActionButton>
              <ActionButton
                secondary
                onClick={() => window.open("https://maps.app.goo.gl/FFxgc95g9dC4phus5")}
              >
                get direction
              </ActionButton>

            </ButtonWrapper>
          </ContentWrapper>
        </Section>

        {/* Right Section */}
        <Section right>
          <ContentWrapper>
            <InfoItem>
              <Icon src={icon2} alt="Phone" />
              <InfoText>
                <a href="tel:+919072372377" style={{ textDecoration: "none", color: "inherit" }}>
                  +91 90723 72377
                </a>
              </InfoText>
            </InfoItem>
            <InfoItem>
              <Icon src={icon3} alt="Email" />
              <InfoText>
                <a href="mailto:info@classichomes.in" style={{ textDecoration: "none", color: "inherit" }}>
                  info@classichomes.in
                </a>
              </InfoText>
            </InfoItem>
            <InfoItem>
              <Icon src={icon1} alt="Location" />
              <InfoText>3rd Floor, Classic Capitol, MRA-83, Mavelipuram, Kakkanad P.O., Ernakulam - 682030, Kerala</InfoText>
            </InfoItem>

          </ContentWrapper>
        </Section>
      </Container>
      {showModal && (
        <DownloadModal>
          <ModalContent>
            <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
            <ContactFormSection />

          </ModalContent>
        </DownloadModal>
      )}
    </>
  );
};

export default LegacySection;

const DownloadModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  position: relative;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
`;

/* Styled Components */
const Container = styled.div`
    display: flex;
    width: 100%;
    min-height: 600px;
    flex-direction: row;
    
    @media (max-width: 768px) {
        flex-direction: column;
        min-height: auto;
    }
`;

const Section = styled.div`
    width: 50%;
    margin: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 40px;
    
    @media (max-width: 1200px) {
        margin: 20px;
        padding: 30px;
    }
    
    @media (max-width: 768px) {
        width: auto;
        margin: 20px;
        padding: 30px;
    }
    
    @media (max-width: 480px) {
        margin: 10px;
        padding: 20px;
    }
`;

/* ContentWrapper for centered content with left-aligned text */
const ContentWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    text-align: left;
    
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const Title = styled.h2`
    font-size: 36px;
    color: #FF0000;
    margin-bottom: 10px;
    
    @media (max-width: 1200px) {
        font-size: 32px;
    }
    
    @media (max-width: 768px) {
        font-size: 28px;
    }
    
    @media (max-width: 480px) {
        font-size: 24px;
    }
`;

const Subtitle = styled.h3`
    font-size: 20px;
    color: #17294A;
    margin-bottom: 15px;
    
    @media (max-width: 768px) {
        font-size: 18px;
    }
    
    @media (max-width: 480px) {
        font-size: 16px;
    }
`;

const Description = styled.p`
    font-size: 16px;
    color: #606060;
    margin-bottom: 20px;
    
    @media (max-width: 480px) {
        font-size: 14px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 15px;
    
    @media (max-width: 480px) {
        flex-direction: column;
    }
`;

const ActionButton = styled.button`
    background-color: ${(props) => (props.secondary ? "transparent" : "#FF383B")};
    color: ${(props) => (props.secondary ? "#014D68" : "#FFFFFF")};
    border: ${(props) => (props.secondary ? "2px solid #014D68" : "none")};
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight:700;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    
    &:hover {
        opacity: 0.8;
    }
    
    @media (max-width: 480px) {
        font-size: 14px;
        padding: 10px 15px;
    }
`;

const InfoItem = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
        margin-bottom: 15px;
    }
`;

const InfoText = styled.p`
    font-size: 20px;
    font-weight: 600;
    color: #17294A;
    
    @media (max-width: 1200px) {
        font-size: 18px;
    }
    
    @media (max-width: 768px) {
        font-size: 16px;
    }
    
    @media (max-width: 480px) {
        font-size: 14px;
    }
`;
const Icon = styled.img`
    width: 24px; /* Adjust size as needed */
    height: 24px;
    
    @media (max-width: 768px) {
        width: 20px;
        height: 20px;
    }
`;
