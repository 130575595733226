import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import bgImage from '../../../assets/images/indianearth/herocover.webp'
import mobilebg from '../../../assets/images/indianearth/mobilecover.jpeg'
import { ReactComponent as SVG1 } from '../../../assets/images/indianearth/heroscan.svg'; // Replace with actual paths
import { ReactComponent as SVG2 } from '../../../assets/images/indianearth/herologo.svg'; // Replace with actual paths
import svg from '../../../assets/images/indianearth/herofoot.svg'
import { CustomContext } from '../../context/StoreContext';
import OnloadPopUp from '../modal/OnloadPopUp';
import brochure from '../../../assets/brochure.pdf'
import ContactFormSection from './ContactModal';


const HeroSection = () => {
  const [showModal, setShowModal] = useState(false);


  return (
    <>
      <HeroContainer>
        <SVGContainer>
          <SVG2 />
        </SVGContainer>
        <Overlay />
        <Content>
          <Title>
            experience the <span className="artistry">art<span className="red-dot">i</span>stry</span> of fine living
          </Title>
          <Subtitle1>welcome to indian earth, the first ever artisan apartments</Subtitle1>

        </Content>


        <SmallText> <StyledSVG /><br /> K-RERA/PRJ/ERN/025/2025 <br /> www.rera.kerala.gov.in</SmallText>
        <BottomSVG src={svg} />

      </HeroContainer>
      <MobileContainer>
        <Overlay />
        <Content2>
          <Description>step into a realm that echoes the eternal allure of indian intricacies, where culture and artisanal allure come together in every laid stone. here, luxury is not merely a concept; it is a living tapestry that speaks of elegance, comfort, and exquisite craftsmanship.</Description>
          <ButtonContainer2>
            {/* {!isModalActive && (
              <Button onClick={() => setenquiryModal(true)}>enquire now</Button>
            )}
            <OnloadPopUp type={'Enquiry'} modal={enquiryModal} setModal={setenquiryModal} /> */}

            <Button onClick={() => setShowModal(true)}>Download Brochure</Button>
          </ButtonContainer2>
        </Content2>
      </MobileContainer>

      {/* New Modal */}
      {showModal && (
        <DownloadModal>
          <ModalContent>
            <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
            <ContactFormSection />

          </ModalContent>
        </DownloadModal>
      )}
    </>

  );
};

export default HeroSection;


const DownloadModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
`;

const DownloadButton = styled.button`
  background: #ff383b;
  color: white;
  font-family: "Noto Sans", sans-serif;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 8px;
  margin-top: 20px;

  &:hover {
    background: #d32f2f;Slider
  }
`;

const HeroContainer = styled.section`
    position: relative;
    width: 100%;
    height: 80vh;
    background: url(${bgImage}) no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    @media (max-width: 768px) {
      height: 70vh; 
    }Slider
      @media (min-width: 768px) {
    height: 90vh;
      
      }
  `;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.3) 80%);
  `;

const SVGContainer = styled.div`
  position: absolute;
  top: 20px; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  display: flex;
  gap: 15px; /* Space between SVGs */
  z-index: 10; /* Ensures it stays on top */
  
  svg {
      height: 60px; /* Adjust size as needed */
      width: auto;

      @media (max-width: 768px) {
      height: 46px; /* Reduce size on smaller screens */
    }
  }Slider
`;
const ButtonContainer = styled.div`
    display: flex;
    gap: 15px; /* Space between buttons */
    justify-content: center;
    margin-top: 20px; /* Adjust as needed */
     @media (max-width: 768px) {
      display: none;
    }
`;

const ButtonContainer2 = styled.div`
    display: flex;
    gap: 15px; /* Space between buttons */
    justify-content: center;
    margin-top: 20px; /* Adjust as needed */Slider
    margin-bottom: 20px;
    
`;


const Content = styled.div`
    position: relative;
    z-index: 2;
    max-width: 870px;
    padding: 20px; 
    bottom : 30px;  
  `;

const Title = styled.h1`
  font-size: 96px;
  font-weight: 400;
  font-family: "Viaoda Libre", serif;
  line-height: 87px;
  max-width: 810px;
  margin: 0 auto 15px;
  font-variant-ligatures: none;

  .artistry {
    display: inline-block;
    position: relative;
  }

  .red-dot {
    position: relative;
    display: inline-block;
  }

  .red-dot::after {
    content: "•";  /* Adds a red dot above */
    color: red;
    font-size: 0.51em;  /* Reduce dot size */
    position: absolute;
    top: -0.48em;  /* Adjust dot positioning */
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 1024px) {
    font-size: 48px;
    line-height: 40px;
    max-width: 410px;
    
    .red-dot::after {
      top: -0.45em;
    }
  }

  @media (min-width: 1200px) {  
    max-width: 1000px;
    
    .red-dot::after {
      font-size: 0.49em;
    }
  }

  @media (max-width: 425px) {
    font-size: 36px;
    line-height: 34px;
    max-width: 230px;
    
    .red-dot::after {
      font-size: 0.52em;
      top: -0.47em;
    }
  }
`;
const Subtitle = styled.p`
  font-weight: 400; /* Bold */
  font-size: 16px;
  line-height: 156%;
    margin-bottom: 30px;
  
    @media (max-width: 1024px) {
      font-size: 12px;
    }
       @media (max-width: 768px) {
      display: none;
    }
  `;
const Subtitle1 = styled.h3`
  font-weight: 700; /* Bold */
  font-size: 16px;
  color: white;
  padding: 10px 20px;
  border: 1px solid white; /* White border */
  border-radius: 20px; /* Rounded border */
  display: inline-block; /* Keeps it compact */
  margin-top:8px;
  @media (max-width: 1024px) {
      font-size: 12px;
    }
      @media (max-width: 425px) {
      width:80%
      padding: 6px 8px;
      }
 
`;


const Button = styled.button`
    background: #FF383B;
    color: white;
    font-family: "Noto Sans", sans-serif;
    border: none;
    padding: 12px 24px;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 8px;
    margin-bottom:8px;
   
    @media (max-width: 768px) {
    padding: 12px 16px;  /* Reduce padding */
    font-size: 1rem;  /* Reduce font size */
  }

  @media (max-width: 480px) {
    padding: 8px 12px;  /* Further reduce padding for smaller screens */
    font-size: 0.9rem;
  }
  `;

const SmallText = styled.p`
  position: absolute;
  bottom: 100px;
  left: 25px;
  font-size: 12px;
  color: white;
  font-family: "Noto Sans", sans-serif;
  opacity: 0.8;
  text-align: left;
   @media (max-width: 768px) {
      font-size: 10px; 
      bottom: 50px;
    }
`;
const StyledSVG = styled(SVG1)`
  width: 50px;
  height: auto; /* Maintain aspect ratio */

  @media (max-width: 1024px) {
    width: 40px;
  }

  @media (max-width: 768px) {
    width: 30px;
  }

  @media (max-width: 480px) {
    width: 25px;
  }
`;
const Button2 = styled.button`
  background: transparent;
  color: white;
  font-family: "Noto Sans", sans-serif;
  border: 2px solid white; /* White border */
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 8px;

 @media (max-width: 768px) {
    padding: 12px 16px;  /* Reduce padding */
    font-size: 1rem;  /* Reduce font size */
  }

  @media (max-width: 480px) {
    padding: 8px 12px;  /* Further reduce padding for smaller screens */
    font-size: 0.9rem;
  }

`;

const BottomSVG = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Ensures it spans the entire screen width */
    height: auto; /* Adjust height proportionally */
    max-height: 120px; /* Adjust as needed */
    object-fit: cover; /* Ensures it doesn't stretch */
    z-index: 1; /* Keeps it below other content */

    @media (max-width: 768px) {
      max-height: 80px; /* Adjust height for smaller screens */
    }
`;
const MobileContainer = styled.section`
    position: relative;
    width: 100%;
    height: 70vh;
    background: url(${mobilebg}) no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white; 
    @media (min-width: 426px) {
        height: 30vh; /* Full height on tablets and above */
        
    }
    @media (min-width: 768px) {
        height: 40vh; /* Full height on tablets and above */
        
    }
    
  }
  `;

const Content2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 426px) {
  justify-content: center;
  margin:0 10px;
  }
`;

const Description = styled.h2`
  position: relative;
  margin: 20px; /* Pushes text to the top */
  font-size: 14px;
  line-height: 156%;
  z-index: 1;
  @media (min-width: 768px) {
    width: 60%;
    font-size: 18px;

  }
  @media (min-width: 1024px) {
    width: 70%;
    font-size: 18px;
    }
`;