import React, { useState } from "react";
import styled from "styled-components";
import bgImage from '../../../assets/images/indianearth/Vector.png';
import ArrowIcon from '../../../assets/images/indianearth/arrow.png'

const contentData = [
  {
    id: 1,
    title: "educational institutions",
    content: [
      { name: "Rajagiri Higher Secondary School", distance: "1.5 km" },
      { name: "St. Paul's International School", distance: "1.9 km" },
      { name: "Cochin University (CUSAT)", distance: "300 m" },
      { name: "Rajagiri College of Social Sciences", distance: "1.3 km" },
      { name: "SCMS Cochin School of Business", distance: "2.4 km" }
    ]
  },
  {
    id: 2,
    title: "entertainment and shopping",
    content: [
      { name: "Bismi Hyper Market", distance: "160 m" },
      { name: "Lulu Mall", distance: "3.6 km" },
      { name: "Decathlon Sports", distance: "2 km" },
      { name: "Rajagiri Sports & Cultural Centre", distance: "1.5 km" }
    ]
  },
  {
    id: 3,
    title: "transportation",
    content: [
      { name: "National Highway", distance: "100 m" },
      { name: "CUSAT Metro Station", distance: "350 m" },
      { name: "Cochin International Airport", distance: "18 km" }
    ]
  },
  {
    id: 4,
    title: "healthcare facilities",
    content: [
      { name: "AIMS Hospital", distance: "4.5 km" },
      { name: "Kalamassery Medical Centre", distance: "1.2 km" },
      { name: "Kinder Multispecialty Hospitals", distance: "1.5 km" },
      { name: "Amrita Hospital", distance: "6.5 km" }
    ]
  }
];

const TwoSectionComponent = () => {
  // Set the first dropdown (id:1) as selected and expanded initially
  const [selectedOption, setSelectedOption] = useState(1);
  const [expandedOptionId, setExpandedOptionId] = useState(1);

  const handleOptionClick = (id) => {
    setSelectedOption(id);
    // For mobile view, toggle the expanded option
    setExpandedOptionId(expandedOptionId === id ? null : id);
  };

  const selectedContent = contentData.find(item => item.id === selectedOption);

  return (
    <Container hasExpandedContent={expandedOptionId !== null}>
      <LeftSection>
        <SvgBackground />
        <LeftContent>
          <ContentWrapper>
            <TitleRow>
              <VerticalBar />
              <SectionTitle>perfect <RedText>connectivity</RedText>  comfortably close</SectionTitle>
            </TitleRow>
            <OptionsList>
              {contentData.map((option, index) => (
                <React.Fragment key={option.id}>
                  <OptionItem
                    isActive={selectedOption === option.id}
                    onClick={() => handleOptionClick(option.id)}
                    isLast={index === contentData.length - 1 && expandedOptionId !== option.id}
                  >
                    <OptionText isActive={selectedOption === option.id}>{option.title}</OptionText>
                    <IconWrapper
                      isExpanded={expandedOptionId === option.id}
                      isActive={selectedOption === option.id}
                    >
                      <img src={ArrowIcon} alt="arrow" />
                    </IconWrapper>
                  </OptionItem>

                  {/* Mobile dropdown content */}
                  {expandedOptionId === option.id && (
                    <MobileContentDropdown>
                      <ContentList>
                        {option.content.map((item, idx) => (
                          <ListItem key={idx}>
                            <div>
                              <span>{item.name}</span>{"   "}
                              <DistanceText>{item.distance}</DistanceText>
                            </div>
                          </ListItem>
                        ))}
                      </ContentList>
                    </MobileContentDropdown>
                  )}
                </React.Fragment>
              ))}
            </OptionsList>
            <ActionButton2
              secondary
              onClick={() => window.open("https://maps.app.goo.gl/FFxgc95g9dC4phus5")}
            >
              get direction
            </ActionButton2>
          </ContentWrapper>
        </LeftContent>
      </LeftSection>

      <RightSection>
        <RightContent>
          <ContentList>
            {selectedContent.content.map((item, index) => (
              <ListItem key={index}>
                <div>
                  <span>{item.name}</span>{"   "}
                  <DistanceText>{item.distance}</DistanceText>
                </div>
              </ListItem>
            ))}
          </ContentList>
          <ActionButton
            secondary
            onClick={() => window.open("https://maps.app.goo.gl/FFxgc95g9dC4phus5")}
          >
            get direction
          </ActionButton>
        </RightContent>
      </RightSection>
    </Container>
  );
};

export default TwoSectionComponent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${props => props.hasExpandedContent ? 'auto' : '400px'};

  @media (min-width: 768px) {
    flex-direction: row;
    height: 500px;
    min-height: 500px;
  }
`;

const LeftSection = styled.div`
  position: relative;
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (min-width: 768px) {
    width: 50%;
  }
  
  @media (max-width: 768px) {
    padding: 30px 0;
    min-height: auto;
    height: auto;
  }
`;


const ActionButton = styled.button`
    background-color: ${(props) => (props.secondary ? "transparent" : "#FF383B")};
    color: ${(props) => (props.secondary ? "#014D68" : "#FFFFFF")};
    border: ${(props) => (props.secondary ? "2px solid #014D68" : "none")};
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight:700;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    margin-top: 30px;
    
    &:hover {
        opacity: 0.8;
    }
    
    @media (max-width: 480px) {
        font-size: 14px;
        padding: 10px 15px;
    }
`;

const ActionButton2 = styled.button`
    background-color: ${(props) => (props.secondary ? "transparent" : "#FF383B")};
    color: ${(props) => (props.secondary ? "#014D68" : "#FFFFFF")};
    border: ${(props) => (props.secondary ? "2px solid #014D68" : "none")};
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight:700;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    margin-top: 30px;
    width: 70%;
    
    &:hover {
        opacity: 0.8;
    }
    
    @media (max-width: 480px) {
        font-size: 14px;
        padding: 10px 15px;
    }
        @media (min-width: 768px) {
        display: none; /* Hide on screens greater than 767px */
    }
`;
const ContentWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 20px 0;
`;

const SvgBackground = styled.div`
  background: url(${bgImage}) no-repeat center center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const LeftContent = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const RightSection = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  
  padding: 40px;
  flex: 1;

  @media (min-width: 768px) {
    width: 50%;
  }
  
  @media (max-width: 767px) {
    display: none; /* Hide right section on mobile */
  }
`;

const RightContent = styled.div`
  max-width: 500px;
  width: 100%;
  text-align: left;
  gap: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  gap: 15px;
  width: 100%;
  margin-bottom: 30px;
  
  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const VerticalBar = styled.div`
  width: 4px;
  height: 70px;
  background-color: #FF0000;
  opacity: 0.7;
`;

const SectionTitle = styled.h2`
  font-size: 38px;
  font-weight: 400;
  color: #014D68;
  max-width: 100%;
  margin: 0;
  line-height: 1.2;
  text-align: left;
  
    @media (max-width: 1440px) { 
    font-size: 36px; 
  }
  
  @media (max-width: 1280px) { 
    font-size: 34px; 
  }

  @media (max-width: 1024px) { 
    font-size: 32px; 
  }

  @media (max-width: 768px) { 
    font-size: 28px; 
  }

  @media (max-width: 480px) { 
    font-size: 24px; 
  }

  @media (max-width: 360px) { 
    font-size: 22px; 
  }
`;

const OptionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 400px;
  border-radius: 18px;
  background: transparent;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const OptionItem = styled.li`
  padding: 15px;
  cursor: pointer;
  background-color: ${props => props.isActive ? '#014D68' : 'rgba(255, 255, 255, 0.85)'};
  color: ${props => props.isActive ? '#ffffff' : '#333333'};
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${props => props.isLast ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'};

  &:hover {
    background-color: ${props => props.isActive ? '#014D68' : '#e0e0e0'};
  }
`;

const OptionText = styled.span`
  flex-grow: 1;
  font-size: 18px;
  font-weight: ${props => props.isActive ? '700' : '400'};
  color: ${props => props.isActive ? '#FFFFFF' : '#014D68'}; 
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  
  /* Apply rotation only on desktop */
  @media (min-width: 768px) {
    transition: transform 0.3s ease;
    transform: ${props => props.isExpanded ? 'rotate(90deg)' : 'rotate(0)'};
  }
  
  img {
    width: 6px;
    height: 10.8px;
    /* If your SVG needs to change color based on active state */
    filter: ${props => props.isActive ? 'brightness(0) invert(1)' : 'none'};
  }
`;

// Mobile dropdown content with improved styling
const MobileContentDropdown = styled.div`
  background-color: #f9f9f9;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
  @media (min-width: 768px) {
    display: none; /* Hide on desktop */
  }
`;

const ContentList = styled.ul`
  padding-left: 20px;
  list-style-type: disc;
  width: 100%;
  margin-bottom: 0;
`;

const ListItem = styled.li`
  margin-bottom: 12px;
  line-height: 1.6;
  text-align: left;
  padding-left: 10px;
  color: #014D68;
  font-weight: 700;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 768px) {
    font-size: 10px;
  }
  
  &::marker {
    font-size: 1.5em;
    color: #014D68;
  }
`;

const DistanceText = styled.span`
  color: #FF0000;
  font-weight: 600;
  margin-left: 10px;
  
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 4px;
    display: inline-block;
  }
`;
const RedText = styled.span`
  color: #FF0000;
  font-weight: 400;
  display: inline;
`;
