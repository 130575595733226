import React, { useEffect, useState } from 'react'
import Helmet from '../includes/Helmet'
import Header from '../includes/Header'
import WorkshopHeader from '../includes/WorkshopHeader'
import Footer from '../includes/Footer'
import Loader from '../includes/helper/Loader'
import { accountsConfig } from "../../axiosConfig";
import OnloadPopUp from "../includes/modal/OnloadPopUp";
import HeroSection from '../includes/indianearth/HeroSection'
import FusionSection from '../includes/indianearth/FusionSection'
import CardSection from '../includes/indianearth/CardSection'
import Amenities from '../includes/indianearth/Amenities'
import SwiperComponent from '../includes/indianearth/SwiperComponent'
import img1 from '../../assets/images/indianearth/elevation1.png'
import img2 from '../../assets/images/indianearth/NIGHT_VIEW_1.png'
import img11 from '../../assets/images/indianearth/Night1.png'

import img3 from '../../assets/images/indianearth/scarone.png'
import img4 from '../../assets/images/indianearth/scartwo.png'
import img5 from '../../assets/images/indianearth/scarthree.png'

import img6 from '../../assets/images/indianearth/gate1.png'
import img7 from '../../assets/images/indianearth/Home_theatre.webp'
import img8 from '../../assets/images/indianearth/carrom_area.webp'
import img9 from '../../assets/images/indianearth/LOUNG_out.webp'
import img10 from '../../assets/images/indianearth/Lobby_Floor.webp'

import img13 from '../../assets/images/indianearth/new1.png'
import img12 from '../../assets/images/indianearth/new2.png'
import img14 from '../../assets/images/indianearth/new3.png'
import img15 from '../../assets/images/indianearth/new4.png'

import NewSwiper from '../includes/indianearth/NewSwiper'
import TwoSectionComponent from '../includes/indianearth/TwoSectionComponent'
import PlanSection from '../includes/indianearth/PlanSection'
import ContactFormSection from '../includes/indianearth/ContactFormSection'
import LegacySection from '../includes/indianearth/LegacySection'


const IndianEarth = () => {
    const [modal, setmodal] = useState(false);
    const [loader, setloader] = useState(true);
    const [datas, setdatas] = useState({});
    const images =[img1,img2,img6,img11];
    const images2 =[img3,img4,img5,img7,img8,img9,img10,img12,img13,img14,img15];
    useEffect(() => {
        setTimeout(() => {
            setloader(false);
        }, 3000);

        // delaying onload pop
        setTimeout(() => {
            setmodal(true);
        }, 20000);
    }, []);

    // geting seo tags
    useEffect(() => {
        accountsConfig
            .get("web/list-link-seo/", {
                params: {
                    links: window.location.pathname,
                },
            })
            .then((res) => {
                const { data, StatusCode } = res.data.app_data;
                if (StatusCode === 6000) {
                    setdatas(data[0]);
                } else {
                    setdatas({});
                }
            });
    }, []);
    return (
        <>
            <Helmet
                titleData={datas?.meta_title}
                descriptionData={datas?.meta_description}
            />
            {/* <Header /> */}
            <WorkshopHeader />
            <HeroSection />
            <FusionSection />
            <CardSection />
            <Amenities />   
            <SwiperComponent images={images}/>
            <TwoSectionComponent /> 
            <NewSwiper images={images2}/>
            <PlanSection />
            <ContactFormSection />
            <LegacySection />
            <Footer />
            <OnloadPopUp modal={modal} setModal={setmodal} />
            <Loader status={loader} />
        </>
    )
}

export default IndianEarth