import React from 'react'
import bgImage from '../../../assets/images/indianearth/amencover.jpeg'
import styled from 'styled-components'
import swimlogo from '../../../assets/images/indianearth/swimlogo.png'
import gymlogo from '../../../assets/images/indianearth/gymlogo.png'
import gardenlogo from '../../../assets/images/indianearth/gardenlogo.png'
import movielogo from '../../../assets/images/indianearth/movielogo.png'
import partylogo from '../../../assets/images/indianearth/partylogo.png'
import playlogo from '../../../assets/images/indianearth/playlogo.png'
import yogalogo from '../../../assets/images/indianearth/yogalogo.png'
import sofalogo from '../../../assets/images/indianearth/sofalogo.png'
import wheelchair from '../../../assets/images/indianearth/wheelchair.png'
import aedlogo from '../../../assets/images/indianearth/aedlogo.png'
import TransparentCard from './TransparentCard'


const Amenities = () => {
  const amenitiesData = [
    { icon: swimlogo, title: "swimming pool", description: "a splash of serenity for your day" },
    { icon: gymlogo, title: "gymnasium", description: "empower your fitness journey" },
    { icon: gardenlogo, title: "rooftop garden", description: "rejuvenate and celebrate" },
    { icon: movielogo, title: "home theater", description: "taking your entertainment to new heights" },
    { icon: partylogo, title: "party hall", description: "a space to craft & cherish your legacy" },
    { icon: playlogo, title: "children’s play area", description: "where imaginations run wild" },
    { icon: yogalogo, title: "yoga space", description: "rediscover peace and balance" },
    { icon: sofalogo, title: "classical themed lobby", description: "where heritage meets modernity" },
    { icon: wheelchair, title: "wheelchair & stretcher", description: "Accessibility and mobility for safe transportation" },
    { icon: aedlogo, title: "defibrillator (AED)", description: "where heritage meets modernity" },
  ];

  return (
    <>
      <AmenContainer>
        <Content>
          <Title>amenities & lifestyle</Title>
          <Subtitle>welcome to indian earth—the first-ever artisan apartments. nestled in the heart of kalamassery, this exclusive residence brings together old-world charm and modern elegance. designed to celebrate artistry, every detail here is a reflection of timeless luxury.</Subtitle>
          <CardGrid>
            {amenitiesData.map((amenity, index) => (
              <TransparentCard key={index} image={amenity.icon} title={amenity.title} description={amenity.description} />
            ))}
          </CardGrid>
        </Content>

      </ AmenContainer>


    </>
  )
}

export default Amenities


const AmenContainer = styled.section`
        position: relative;
        width: 100%;
        min-height: 70vh;
        background: url(${bgImage}) no-repeat center center/cover;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        `;

const Title = styled.h1`
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
        max-width: 810px;
        margin: 0 auto 15px;
             @media (max-width: 1024px) {
          font-size: 36px;
  }
        @media (max-width: 768px) {
          font-size: 24px;
  }
        `;


const Content = styled.div`
        position: relative;
        z-index: 2;
        max-width: 960px;
        padding: 20px;
        bottom : 30px;
        margin-top: 100px;
        `;


const Subtitle = styled.p`
        font-weight: 400; /* Bold */
        font-size: 16px;
        line-height: 156%;
        margin-bottom: 30px;

        @media (max-width: 1024px) {
          font-size: 14px;
  }
        @media (max-width: 768px) {
          font-size: 12px;
  }
        `;

const CardGrid = styled.div`
        display: grid;
        grid-template-columns: repeat(5, 1fr); /* 5 cards per row */
        gap: 20px;
        justify-content: center;
        align-items: center;
        padding: 20px;
      
        @media (max-width: 1024px) {
          grid-template-columns: repeat(4, 1fr); /* 3 per row on tablets */
        }
      
        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr); /* 2 per row on small screens */
        }
      
       
      `;