import React from 'react';
import styled from 'styled-components';

const TransparentCard = ({ image, title, description }) => {
  return (
    <CardContainer>
      <IconWrapper>{image && <img src={image} alt={title} />}</IconWrapper>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContainer>
  );
};

export default TransparentCard;

const CardContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 0px;
    
  }
`;

const CardDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-top:4px;
  }
`;
