import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

//imports
import logo from "../../assets/images/logo.svg";
import Drawer from "./Drawer";
import whatsIcon from "../../assets/images/icons/whtsappIcon.svg";
import callIcon from "../../assets/images/icons/callIcon.svg";
import OnloadPopUp from "./modal/OnloadPopUp";
import { CustomContext } from "../context/StoreContext";

function Header() {
  const [active, setactive] = useState(false);
  const [enquiryModal, setenquiryModal] = useState(false);
  const { active: isModalActive } = useContext(CustomContext);

  const navigate = useNavigate();
  const headerRef = useRef();


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if(currentScrollPos > 50){
        if(headerRef.current){
          headerRef.current.style.position = "fixed";
        }
      }else{
        if(headerRef.current){
          headerRef.current.style.position = "unset";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container ref={headerRef}>
        <Wrapper className="wrapper">
          <Content>
            <Logo onClick={() => navigate("/")}>
              <img src={logo} alt="logo" />
            </Logo>
            <Cover>
              <Navs>
                <NavItems onClick={() => navigate("/")}>Home</NavItems>
                <NavItems onClick={() => navigate("/apartments-for-sale-in-kochi")}>
                  Apartments
                </NavItems>
                <NavItems onClick={() => navigate("/villas-for-sale-in-kochi")}>Villas</NavItems>
                <NavItems onClick={() => navigate("/our-community")}>
                  Our Community
                </NavItems>
              </Navs>
              <Hamburger onClick={() => setactive(true)}>
                <i class="ri-menu-line"></i>
              </Hamburger>
            </Cover>
          </Content>
        </Wrapper>
      </Container>
      <Drawer navigate={navigate} setactive={setactive} active={active} />
      {/* <FloatingIcons>
        <WhatsappIcon href="https://wa.me/+919072888518?text=Hi" target="_blank">
          <img src={whatsIcon} alt="" />
        </WhatsappIcon>
        <CallIcon href="tel:+919072372377" target="_blank">
          <img src={callIcon} alt="" />
        </CallIcon>
      </FloatingIcons> */}
      {!isModalActive && (
        <EnquiryFloatingBtn onClick={() => setenquiryModal(true)}>
          Enquire Now
        </EnquiryFloatingBtn>
      )}

      <OnloadPopUp type={'Enquiry'} modal={enquiryModal} setModal={setenquiryModal} />
    </>
  );
}

export default Header;

const Container = styled.div`
  background-color: #2a2b29;
  color: #fff;
  padding: 13px 0;

  // make the header sticky
  top:0;
  right:0;
  width: 100%;
  z-index: 251;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.div`
  width: 130px;
  cursor: pointer;
`;
const Cover = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 1300px) {
    justify-content: end;
  }
`;
const Navs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;

  @media (max-width: 1300px) {
    display: none;
  }
`;
const NavItems = styled.div`
  cursor: pointer;
`;
const Hamburger = styled.div`
  font-size: 28px;
  cursor: pointer;
`;

const FloatingIcons = styled.div`
  display: flex;
  position: fixed;
  right: 20px;
  top: 60%;
  padding: 10px;
  gap: 15px;
  z-index: 250;
  flex-direction: column;

  @media (max-width: 950px) {
    right: 25px;
  }
  @media (max-width: 580px) {
    right: 5px;
    top: 75%;
    padding: 5px;
  }
`;

const WhatsappIcon = styled.a`
  display: block;
  cursor: pointer;
  img {
    object-fit: contain;
    width: 50px;
    @media (max-width: 950px) {
      width: 45px;
    }

    @media (max-width: 580px) {
      width: 45px;
    }
  }
`;

const CallIcon = styled.a`
  display: block;
  cursor: pointer;
  img {
    object-fit: contain;
    width: 50px;
    @media (max-width: 950px) {
      width: 45px;
    }
  }
`;

const EnquiryFloatingBtn = styled.div`
  position: fixed;
  top: 20%;
  right: 0;
  border-radius: 0px 5px 5px 0px;
  padding: 25px 8px;
  background-color: #18294A;
  color: #fff;
  font-family: "gellix-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  z-index: 251;
  transform: rotate(180deg);
  cursor: pointer;

  @media (max-width: 580px) {
    padding: 16px 4px;
    font-size: 20px;
    position: fixed;
    top: 30%;
    right: -1px;
    border-radius: 0px 5px 5px 0px;
    padding: 13px 8px;
    background-color: #18294A;
    color: rgb(255, 255, 255);
    font-family: gellix-regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.1px;
    display: flex;
    align-items: center;
    justify-content: center;
    writing-mode: vertical-rl;
    z-index: 251;
    transform: rotate(180deg);
    cursor: pointer;
  }
`;
