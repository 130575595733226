import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import bgImage from '../../../assets/images/indianearth/planbg.jpg'
import floor1 from '../../../assets/images/indianearth/floor1.png'
import floor2 from '../../../assets/images/indianearth/floor2.png'
import floor3 from '../../../assets/images/indianearth/floor3.png'
import floor4 from '../../../assets/images/indianearth/floor4.png'
import floor5 from '../../../assets/images/indianearth/floor5.png'
import floor6 from '../../../assets/images/indianearth/floor6.png'
import floor7 from '../../../assets/images/indianearth/floor7.png'
import floor8 from '../../../assets/images/indianearth/floor8.png'
import floor9 from '../../../assets/images/indianearth/floor9.png'
import brochure from '../../../assets/brochure.pdf'
import ContactFormSection from './ContactModal'

const images = [
  floor1,
  floor2,
  floor3,
  floor4,
  floor5,
  floor6,
  floor7,
  floor8,
  floor9
];

const PlanSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [thumbnailStartIndex, setThumbnailStartIndex] = useState(0);
  const thumbnailsToShow = 4; // Number of thumbnails to display at once

  // Auto-slide functionality remains the same
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // Handle thumbnail click remains the same
  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  // Navigate thumbnails left
  const handlePrevThumbnails = () => {
    setThumbnailStartIndex(prev =>
      Math.max(0, prev - thumbnailsToShow)
    );
  };

  // Navigate thumbnails right
  const handleNextThumbnails = () => {
    setThumbnailStartIndex(prev =>
      Math.min(images.length - thumbnailsToShow, prev + thumbnailsToShow)
    );
  };

  // Get visible thumbnails
  const visibleThumbnails = images.slice(
    thumbnailStartIndex,
    thumbnailStartIndex + thumbnailsToShow
  );

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = brochure;
    link.download = "brochure.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <FusionContainer>
        <ContentWrapper>
          {/* ✅ LEFT SIDE */}
          <LeftContent>
            <TitleRow>
              <VerticalBar />
              <Title>floor plans & specifications</Title>
            </TitleRow>
            <FeatureList>
              <FeatureItem><BoldText>flooring </BoldText>: premium vitrified tiles for drawing, dining, and bedrooms.</FeatureItem>
              <FeatureItem><BoldText>kitchen </BoldText>: bare shell with provisions for modern fittings.</FeatureItem>
              <FeatureItem><BoldText>utilities</BoldText>: solar-powered lighting, centralized gas connection, EV charging.</FeatureItem>
              <FeatureItem><BoldText>safety</BoldText>: video door phones, fire control room, and biometric access.</FeatureItem>
            </FeatureList>
            <Button2 onClick={() => setShowModal(true)}>download brochure</Button2>
          </LeftContent>

          {/* ✅ RIGHT SIDE */}
          <RightContent>
            <CarouselContainer>
              <ImageWrapper>
                <MainImage src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
              </ImageWrapper>

              <ThumbnailNavigation>
                <NavButton onClick={handlePrevThumbnails} disabled={thumbnailStartIndex === 0}>
                  &lt;
                </NavButton>

                <ThumbnailContainer>
                  {visibleThumbnails.map((image, index) => {
                    const actualIndex = thumbnailStartIndex + index;
                    return (
                      <Thumbnail
                        key={actualIndex}
                        src={image}
                        alt={`Thumbnail ${actualIndex + 1}`}
                        isActive={actualIndex === currentIndex}
                        onClick={() => handleThumbnailClick(actualIndex)}
                      />
                    );
                  })}
                </ThumbnailContainer>

                <NavButton
                  onClick={handleNextThumbnails}
                  disabled={thumbnailStartIndex >= images.length - thumbnailsToShow}
                >
                  &gt;
                </NavButton>
              </ThumbnailNavigation>
            </CarouselContainer>
            <Button3 onClick={() => setShowModal(true)}>download brochure</Button3>

          </RightContent>
        </ContentWrapper>
      </FusionContainer>

      {/* New Modal */}
      {showModal && (
        <DownloadModal>
          <ModalContent>
            <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
            <ContactFormSection />

          </ModalContent>
        </DownloadModal>
      )}
      </>
    );
  };

export default PlanSection;

const DownloadModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  position: relative;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
`;

// ✅ STYLED COMPONENTS
const FusionContainer = styled.section`
  padding: 60px 0;
  position: relative;
  width: 100%;
  min-height: 70vh;
  background: rgba(0, 0, 0, 0.9) url(${bgImage}) no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  @media (max-width: 767px) {
    padding: 40px 0;
    min-height: 60vh; /* Allow natural height on mobile */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  align-items: center; /* ✅ Center children vertically */
  justify-content: center;
  width: 90%;
  gap: 60px;

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 95%;
    gap: 20px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    gap: 20px;
    width: 100%;
    padding: 0 15px;
    align-items: center; /* Ensure children are centered on smaller screens */
  }
`;

const LeftContent = styled.div`
  flex: 1; 
  text-align: left;
  max-width: 45%;
  margin: 40px;
  
   @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 45%;
    margin: 20px;
    /* Control the height to match the right content */
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    margin: 10px;
    
    text-align: center;
    max-width: 100%;
    margin: 20px auto; /* Center horizontally with auto margins */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center all child elements */
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  

  @media (max-width: 767px) {
    justify-content: flex-start; /* Align left for small screens */
    text-align: left;
  }
`;


const VerticalBar = styled.div`
  width: 4px;
  height: 70px;
  background-color: #FF0000;
  opacity: 0.7;
`;

const Title = styled.h2`
  font-size: 38px;
  font-weight: 400;
  color: #18294A;
  line-height: 1.2;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 32px;
  }

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 1.3;
  }
  
 
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  
  @media (max-width: 767px) {
  display:none;
    width: 100%; 
    max-width: 400px;
  }
`;
const FeatureItem = styled.li`
  color: #6D7B96;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  padding-left: 20px; /* Add padding for the bullet point */
  position: relative; /* For positioning the bullet */
  
  @media (max-width: 767px) {
    font-size: 12px;
  }

  /* Position the bullet point absolutely so the text doesn't wrap below it */
  &::before {
    content: "●";
    color: red;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 2px; /* Adjust vertical alignment */
  }
`;

const BoldText = styled.span`
  color: black;
  font-weight: 700;
`;

const Button2 = styled.button`
  background: #FF383B;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  margin-top: 20px;
  
  @media (max-width: 767px) {
    display: none;
  }
`;


const Button3 = styled.button`
  background: #FF383B;
  color: white;
  border: none;
  margin: 20px auto; /* Center with auto margins */
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  display: none;
  
  @media (max-width: 767px) {
    display: inline-block;
  }
  
  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 1rem;
  }
`;


/* ✅ RIGHT SIDE */
const RightContent = styled.div`
  flex: 1;
  text-align: center;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  
  /* Add tablet-specific styling */
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 50%;
    /* Ensure the height matches the left content */
    align-self: stretch;
  }
  
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
`;


const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* Make this fill the available space on tablet */
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 100%;
    justify-content: center;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-height: 520px;
  border-radius: 5%;
  overflow: hidden;
  border: 5px solid #ddd;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 1024px) {
    max-height: 450px;
  }
  
  @media (max-width: 767px) {
    max-height: 400px;
    border-radius: 3%;
    border-width: 3px;
  }
  
  @media (max-width: 480px) {
    max-height: 300px;
  }
`;


const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ThumbnailContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  width: auto; /* Change from fixed width to auto */
  max-width: 280px; /* 4 thumbnails (60px) + gaps (3 * 10px) = 270px, rounded up */
  overflow: hidden;
  
  @media (max-width: 480px) {
    max-width: 190px; /* 4 thumbnails (40px) + gaps (3 * 10px) = 190px */
    gap: 5px; /* Reduce gap for small screens */
  }
`;

const Thumbnail = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
  border: ${({ isActive }) => (isActive ? '3px solid #FF0000' : '1px solid #ddd')};
  cursor: pointer;
  transition: all 0.3s ease;
  
  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
    border-radius: 6px; /* Slightly smaller radius for smaller thumbnails */
    border-width: ${({ isActive }) => (isActive ? '2px' : '1px')}; /* Thinner border on small screens */
  }
  
  &:hover {
    transform: scale(1.05);
  }
`;
const ThumbnailNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const NavButton = styled.button`
  background: #FF383B;
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  
  @media (max-width: 480px) {
    width: 34px; /* Make slightly larger on mobile for easier tapping */
    height: 34px;
  }
  
  &:hover {
    background: #e62e31;
    transform: scale(1.1);
  }
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;


