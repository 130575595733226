import React, { useState } from 'react';
import styled from 'styled-components';
import { accountsConfig } from '../../../axiosConfig';
import ClipLoader from 'react-spinners/ClipLoader';
import Swal from "sweetalert2";
import brochure from '../../../assets/brochure.pdf';

const ContactFormSection = () => {
    const [formData, setFormData] = useState({ name: '', phone: '', email: '', message: '' });
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = brochure;
        link.download = "brochure.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const submitEnquiry = (e) => {
        e.preventDefault();
        setLoading(true);

        const form = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            form.append(key, value);
        });
        handleDownload();

        accountsConfig.post('projects/quick-enquiry/', form).then((response) => {
            const { StatusCode } = response.data.app_data;
            if (StatusCode === 6000) {
                setLoading(false);
                handleDownload();
                e.target.reset();
                showSuccessAlert();
            }
        });
    };

    return (
        <FormContainer onSubmit={submitEnquiry}>
            <FormTitle>Get Your Free Brochure</FormTitle>

            <FormRow>
                <FormItem>
                    <Label>Your Name*</Label>
                    <TextInput name="name" type="text" required onChange={handleChange} />
                </FormItem>
                <FormItem>
                    <Label>Email</Label>
                    <TextInput name="email" type="email" onChange={handleChange} />
                </FormItem>
            </FormRow>

            <FormRow>
                <FormItem>
                    <Label>Phone Number*</Label>
                    <TextInput name="phone" type="number" required onChange={handleChange} />
                </FormItem>
            </FormRow>

            <FormRow>
                <FormItem className="full-width">
                    <Label>Message</Label>
                    <TextAreaInput name="message" rows={3} onChange={handleChange} />
                </FormItem>
            </FormRow>

            <SubmitButton>
                {loading ? <ClipLoader color="#ffffff" loading={loading} size={20} /> : "Submit & Download PDF"}
            </SubmitButton>
        </FormContainer>
    );
};

export default ContactFormSection;

// 🎨 **Styled Components**
const FormContainer = styled.form`
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    max-width: 400px;  /* Reduce max width */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 480px) {
        padding: 15px;
    }
`;


const FormTitle = styled.h2`
    font-size: 22px;
    font-weight: 600;
    color: #333;
    text-align: center;

    @media (max-width: 480px) {
        font-size: 18px;
    }
`;

const FormRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    @media (max-width: 480px) {
        flex-direction: column;
    }
`;

const FormItem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;

    &.full-width {
        width: 100%;
    }
`;

const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
    color: #555;
    text-align: left;
    
`;

const TextInput = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 15px;
    transition: 0.3s;

    &:focus {
        outline: none;
        border-color: #ff383b;
        box-shadow: 0 0 4px rgba(255, 56, 59, 0.2);
    }

    @media (max-width: 480px) {
        font-size: 14px;
        padding: 8px;
    }
`;

const TextAreaInput = styled.textarea`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 15px;
    transition: 0.3s;
    resize: none;

    &:focus {
        outline: none;
        border-color: #ff383b;
        box-shadow: 0 0 4px rgba(255, 56, 59, 0.2);
    }

    @media (max-width: 480px) {
        font-size: 14px;
        padding: 8px;
    }
`;

const SubmitButton = styled.button`
    background: #ff383b;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;

    &:hover {
        background: #e02527;
    }

    @media (max-width: 480px) {
        font-size: 14px;
        padding: 8px;
    }
`;
const showSuccessAlert = () => {
    Swal.fire({
        position: "center",
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1500,
        iconColor: "#0e2d5e",
    });
};